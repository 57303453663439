// ホームページ

import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Button from "../components/button";

const IndexPage = ({ data }) => {
  return (
    <div>
      <SEO />
      <Layout>
        <section className="top">
          <div className="topContainer">
            <Img
              fluid={data.mainpic.childImageSharp.fluid}
              className="topPic"
            />
            <div className="catch">
              <h1>相氣一進流</h1>
              <p>武道を通して心身の成長を目指す</p>
            </div>
          </div>
        </section>

        <section className="container">
          <h2>お知らせ</h2>
          <section className="importantInfoList">
            {data.allContentfulBlogPost.edges.map(({ node }) => (
              <div className="importantInfo" key={node.id}>
                <Link to={`/blog/${node.slug}`}>
                  <p>{node.title}</p>
                </Link>
              </div>
            ))}
          </section>
          <h2>相氣一進流について</h2>
          <p>
            相氣一進流とは、武田流中村派を長年に渡り修行した鈴木喜中によって創始された武術流儀です。武田流、武田流中村派の流れを組む合気道、居合道、柔術拳法、杖道、双桴術、手裏剣術の稽古しています。また、当流派では、流派名の由来にもなった「相氣の術」を特徴としています。「相氣の術」は、主に剣術で使われていた術です。
          </p>
          <div className="toDetail">
            <Button>
              <Link to={`/about`}>詳しくはこちら</Link>
            </Button>
          </div>
          <h2>相氣一進流 宗家</h2>
          <div className="sokeProfile">
            <div className="sokePic">
              <Img
                fluid={data.sokeProfile.childImageSharp.fluid}
                className="sokeProfilePic"
              />
            </div>
            <div className="sokeGreeting">
              <h3>鈴木喜中</h3>
              <ul>
                <li>相氣一進流　宗家</li>
                <li>
                  特定非営利活動法人　インターナショナル合気道協会　主席師範
                </li>
                <li>公益財団法人　日本体育協会　公認スポーツリーダー</li>
                <li>一般社団法人　日本指導教育協会　指導主任士</li>
                <li>元武田流中村派　上伝師範</li>
                <li>INTERNATIONAL TRADITIONAL BUDO FEDERATION　名誉会員</li>
              </ul>
              <p>
                幼少より坂戸綜武館の豊島正幸奥伝師範にて師事し、武田流中村派を長年に渡り修行し、相氣一進流を創流しました。
              </p>
              <hr />
              <p>
                相氣一進流のホームページをご覧いただきありがとうございます。宗家の鈴木喜中です。長年の武道修行で培った技を後世に残したいという思いから相氣一進流を創始しました。「一騎当千」を目指し、武術、武道の稽古に励んでおります。武道を通して、少しでも伝統文化に興味を持っていただければ、幸甚に存じます。
              </p>
            </div>
          </div>
          <div className="forBeginner">
            <h2 className="welcomeEveryone">武道未経験者大歓迎！</h2>
            <h3 className="welcomeEveryone-subtitle">
              少年部、一般部ともに生徒募集中です！
            </h3>
            <p>
              相氣一進流では、武道経験を問わず様々な方の入会を受け付けております！運動習慣を付けたい、自己防衛の方法を学びたい等のリクエストも大歓迎です。
              入会を検討している方、武道に興味のある方はまず稽古を体験してみましょう！稽古に参加して、稽古の内容や道場の雰囲気が自分にあうかどうか体験してください！
              1回の体験で稽古の様子は伝わらないと考えの下、当流派では1カ月の体験無料を実施しています。
              見学、体験希望の方は、事前にお問合せください。道着や居合刀の貸し出しもできます。
            </p>
          </div>
          <div className="schedule">
            <h2>稽古スケジュール</h2>
            <p>
              下記の時間に稽古しています。祝日や道場の都合で日程は変更になることがあります。
              月ごとの日程は、こちらをご覧ください。
            </p>
            <div className="calendar">
              <Img
                fluid={data.timeTable.childImageSharp.fluid}
                className="usualSchedule"
              />
            </div>
            <div className="contact">
              <h2>Access & Contact</h2>
              <h3>相氣一進流 東京本部道場</h3>
              <p>〒1760004 東京都練馬区小竹町2-8-8</p>
              <p>03-6750-6530</p>
              <a href="mailto:aikiishinryu21@gmail.com">
                <p>aikiishinryu21@gmail.com</p>
              </a>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3238.3201821983516!2d139.67661701526046!3d35.74293428017991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018ed38793779b1%3A0xe91692056e5dc964!2zTlBP5rOV5Lq644Kk44Oz44K_44O844OK44K344On44OK44Or5ZCI5rCX6YGT5Y2U5Lya!5e0!3m2!1sja!2sjp!4v1594990841295!5m2!1sja!2sjp"
                allowFullScreen
                aria-hidden="false"
                title="本部道場所在地"
              />
              <h3 className="access">道場までのアクセス</h3>
              <ul>
                <li>小竹向原駅2番出口から徒歩1分</li>
                <li>江古田駅から徒歩15分</li>
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query {
    mainpic: file(relativePath: { eq: "mainpic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 100, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    sokeProfile: file(relativePath: { eq: "sokeProfile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    timeTable: file(relativePath: { eq: "schedule.png" }) {
      childImageSharp {
        fluid(maxWidth: 320, quality: 100, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulBlogPost(
      filter: {
        category: { elemMatch: { category: { eq: "重要なお知らせ" } } }
      }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;

export default IndexPage;
